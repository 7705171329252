import http from "../http-common";
import httpFile from "../http-common-file";

class TutorialDataService {
  //reciept
  getDateWorld() {
    return http.get("/getDateWorld");
  }
  createReciept(data) {
    return http.post("/createReciept", data);
  }
  PayReciept(data) {
    return http.post("/PayReciept", data);
  }

  findReciept(classID) {
    return http.post(`/findReciept/id=`, classID);
  }

  findRecieptByDateRange(data) {
    return http.post(`/findRecieptByDateRange/`, data);
  }

  deleteReciept(id) {
    return http.delete(`/deleteReciept/${id}`);
  }
  //reciept***
  getAll() {
    return http.get("/tutorials");
  }

  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  create(data) {
    return http.post("/tutorials", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }
  deleteCauses(id) {
    return http.delete(`/causes/${id}`);
  }
  deleteCategory(id) {
    return http.delete(`/category/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }

  findByClassID(classID) {
    return http.post(`/findByClassID/id=`, classID);
  }
  ////////////////////

  createClass(data) {
    return http.post("/createClass", data);
  }

  updateLevelEduClass(id, data) {
    return http.put(`/updateLevelEduClass/${id}`, data);
  }
  getAllCLass() {
    return http.get(
      "/getAllClass",
      {},
      {
        headers: {
          "ngrok-skip-browser-warning": 1,
        },
      },
    );
  }

  getAllCauses() {
    return http.get("/getAllCauses");
  }

  getCategory() {
    return http.get("/getCategory");
  }
  /////////////////// students
  createStudent(data) {
    return http.post("/createStudent", data);
  }
  deleteStudent(id) {
    return http.delete(`/student/${id}`);
  }

  findStudentByClassID(classID) {
    return http.post(`/findStudentByClassID/id=`, classID);
  }
  findStudentByID(id) {
    return http.post(`/findStudentByID/id=`, id);
  }

  //Category
  createCategory(data) {
    return http.post("/createCategory", data);
  }
  updateCat(id, data) {
    return http.put(`/updateCat/${id}`, data);
  }

  updateCatOrderStudent(id, data) {
    return http.put(`/updateCatOrderStudent/${id}`, data);
  }

  createCauses(data) {
    return http.post("/createCauses", data);
  }
  createDate(data) {
    return http.post("/createDate", data);
  }

  ///marks
  createMarks(data) {
    return http.post("/createMarks", data);
  }
  createMarksEating(data) {
    return http.post("/createMarksEating", data);
  }
  findMarks(classID) {
    return http.post(`/findMarks/id=`, classID);
  }
  updateMark(id, data) {
    return http.put(`/updateMark/${id}`, data);
  }
  updateMarkAdmin(id, data) {
    return http.put(`/updateMarkAdmin/${id}`, data);
  }
  findMarksByDateRange(classID) {
    return http.post(`/findMarksByDateRange/`, classID);
  }

  ///*** FILE
  sendFile(data) {
    return httpFile.post(`/single-file`, data);
  }

  getFile(data) {
    return http.post(`/loadFileID`, data, {
      responseType: "blob",
      observe: "response",
    });
  }

  loadImportStudentsTemplates() {
    return http.get(`/loadImportStudentsTemplates`, {
      responseType: "blob",
      observe: "response",
    });
  }
  loadImportClassTemplates() {
    return http.get(`/loadImportClassTemplates`, {
      responseType: "blob",
      observe: "response",
    });
  }

  /// CLUBS

  crateClub(data) {
    return http.post("/createCLub", data);
  }

  getClubs() {
    return http.get("/getClubs");
  }

  addClubsStudent(data) {
    return http.post("/addClubsStudent", data);
  }
  recalculateClubStat() {
    return http.post("/recalculateClubStat");
  }

  recalculateClubStudent() {
    return http.post("/recalculateClubStudent");
  }

  deleteDublecateMarks(data) {
    return http.post("/deleteDublecateMarks", data);
  }

  //postAD

  createAD(data) {
    return http.post("/createAD", data);
  }
  getAD() {
    return http.get("/getAD");
  }

  deleteAD(id) {
    return http.delete(`/AD/${id}`);
  }

  createDayWeekMenu(data) {
    return http.post("/createDayWeekMenu", data);
  }
  deleteDayWeekMenu(data) {
    return http.post("/deleteDayWeekMenu", data);
  }
  updateDayWeekMenu(id, data) {
    return http.put(`/updateDayWeekMenu/${id}`, data);
  }

  findDayWeekMenuByDateRange(data) {
    return http.post("/findDayWeekMenuByDateRange", data);
  }
  addclassDayWeekMenu(data) {
    return http.post("/addclassDayWeekMenu", data);
  }

  getTelegramToken(data) {
    return http.get("/getTelegramToken", data);
  }
  createTelegramToken(data) {
    return http.post("/createTelegramToken", data);
  }
  updateTelegramToken(data) {
    return http.post("/updateTelegramToken", data);
  }

  //inventary

  createINV(data) {
    return http.post("/createINV", data);
  }
  getAllINV(data) {
    return http.get("/getAllINV", data);
  }
  updateINV(id, data) {
    return http.put(`/updateINV/${id}`, data);
  }
  findINVByID(id) {
    return http.post("/findINVByID/:id", id);
  }
  deleteINV(id) {
    return http.delete(`/deleteINV/${id}`);
  }
}

export function updateAxiosInstance() {
  http.defaults.baseURL = window.myVariable;
}

export default new TutorialDataService();
