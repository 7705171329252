<template>
  <div style="overflow-x: auto">
    <div
      style="
        text-align: center;
        color: rgb(255, 255, 255);
        text-decoration: underline;
        cursor: pointer;
        background-color: rgb(0, 126, 223);
      "
      @click="routeToClass()"
    >
      Классы
    </div>
    <div class="filters">
      <div style="display: flex">
        <div style="width: 270px; padding-top: 7px">Поиск по фамилии:</div>
        <input
          type="text"
          class="form-control"
          v-model="inputName"
          @change="inputNameChange()"
        />
        <button
          style="margin-left: 7px"
          class="btn btn-primary"
          @click="searchByName = inputName"
        >
          Поиск
        </button>
      </div>
      <div style="display: flex">
        <div style="width: 90px; padding-top: 7px; padding-left: 7px">
          Класс
        </div>
        <select
          class="form-select"
          type="number"
          v-model="selectFilterClass"
          @change="changeClass()"
          name=""
          id=""
        >
          <option value="Все">Все</option>
          <option :value="el" v-for="(el, index) in classList" :key="index">
            {{ el.className }}
          </option>
        </select>
      </div>

      <button
        style="margin-left: 7px"
        class="btn btn-dark"
        @click="exportData()"
      >
        ЭКСПОРТ ДАННЫХ
      </button>
    </div>
    <table class="reciept-table" style="width: 96%">
      <caption style="caption-side: top">
        <div class="paginat">
          <button
            @click="changeSelectPaginatListNumber(false)"
            type="submit"
            class="unSelectBtn"
          >
            ...
          </button>
          <button
            type="submit"
            v-for="(btn, index) in viewPaginatBtn"
            :key="index"
            :check="btn.select"
            :class="{
              selectBtn: btn.select,
              unSelectBtn: !btn.select,
            }"
            @click="changeSelectPaginatNumber(btn)"
          >
            {{ btn.Number }}
          </button>
          <button
            @click="changeSelectPaginatListNumber(true)"
            type="submit"
            class="unSelectBtn"
          >
            ...
          </button>
          <div
            style="padding: 7px 7px 7px 7px; font-size: 18px; font-weight: 300"
          >
            {{ paginatBtn.length }}
          </div>
          <div style="width: 100px">
            <select
              class="form-select"
              type="number"
              v-model="paginatAmountTt"
              @change="changePaginatAmount(false)"
              name=""
              id=""
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="10000">10000</option>
            </select>
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th>№</th>
          <th>ID</th>
          <th>ID класса</th>
          <th>Класс</th>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Пол</th>
          <th>Категория</th>
          <th>Состоит в N кружках</th>
          <th style="width: 20px">Документы по питанию сданы</th>
          <th>Приказ</th>
          <th>Справка</th>
          <th>Документы выданы на категорию</th>
          <th>Компенсация</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, index) in searchByNameF" :key="index">
          <td>{{ el.ghostIndex + 1 }}</td>
          <td>{{ el._id }}</td>
          <td>{{ el.classID }}</td>
          <td>{{ el.className }}</td>
          <td>{{ el.FirstName }}</td>
          <td>{{ el.LastName }}</td>
          <td>{{ el.Surname }}</td>
          <td>{{ el.Category }}</td>
          <td>{{ el.Clubs.length }}</td>
          <td v-if="el.Order">
            <div v-if="el.Order.documents">Да</div>
            <div v-else>Нет</div>
          </td>
          <td v-else></td>
          <td v-if="el.Order">
            <div v-if="el.Order.Order.text != ''">
              {{ el.Order.Order.text }} от
              {{ new Date(el.Order.Order.date).toLocaleDateString() }}
            </div>
          </td>
          <td v-else></td>
          <td v-if="el.Order">
            <div v-if="el.Order.reference.text != ''">
              {{ el.Order.reference.text }} от
              {{ new Date(el.Order.reference.date).toLocaleDateString() }}
            </div>
          </td>
          <td v-else></td>
          <td v-if="el.Order">
            <div v-if="el.Order.cat != ''">
              {{ el.Order.cat }}
            </div>
          </td>
          <td v-else></td>
          <td v-if="el.Order">
            <div v-if="el.Order.compensation">
              <div
                @click="showBank(el)"
                style="color: blue; text-decoration: underline; cursor: pointer"
              >
                Да
              </div>
            </div>
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
    <div style="height: 100px"></div>
  </div>
</template>

<script>
import getStudentsMix from "../mixins/getStudents";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      students: [],
      printStudents: [],
      paginatBtn: [],
      paginatAmountTt: 25,
      paginatBtnListAmount: [],
      classList: [],
      selectFilterClass: "Все",
      searchByName: "",
      inputName: "",
    };
  },
  mixins: [getStudentsMix],
  methods: {
    // getStudents() {
    //   getAllStudents();
    // },
    routeToClass() {
      this.$router.push("/class");
    },
    exportData() {
      let studentsExports = this.students;
      let data = [
        [
          "№",
          "Класс (classID)",
          "Фамилия",
          "Имя",
          "Пол",
          "Категория",
          "Документы питания",
          "Приказ",
          "Приказ дата",
          "Справка",
          "Справка дата",
          "Документы Категория",
          "Компенсация",
          "Лицевой счет",
          "Реквизиты банка",
          "Сбербанк",
          "Фамилия Получателя",
          "Имя Получателя",
          "Отчество получателя",
        ],
      ];
      let str = [];
      for (let i = 0; i < studentsExports.length; i++) {
        str.push(i + 1);
        str.push(studentsExports[i].className);
        str.push(studentsExports[i].FirstName);
        str.push(studentsExports[i].LastName);
        str.push(studentsExports[i].Surname);
        str.push(studentsExports[i].Category);
        if (studentsExports[i].Order) {
          str.push(studentsExports[i].Order.documents);
          str.push(studentsExports[i].Order.Order.text);
          str.push(studentsExports[i].Order.Order.date);
          str.push(studentsExports[i].Order.reference.text);
          str.push(studentsExports[i].Order.reference.date);
          str.push(studentsExports[i].Order.cat);
          if (studentsExports[i].Order.compensation) {
            str.push(studentsExports[i].Order.compensation);
            str.push(studentsExports[i].Order.ls);
            str.push(studentsExports[i].Order.bank);
            str.push(studentsExports[i].Order.Sberbank);
            str.push(studentsExports[i].Order.recipientsLastName);
            str.push(studentsExports[i].Order.recipientsFirstName);
            str.push(studentsExports[i].Order.recipientsSurname);
          }
        }
        data.push(str);
        str = [];
      }
      studentsExports = [];
      this.exportXLSX(data);
    },
    exportXLSX(ws_data) {
      console.log(ws_data);
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017, 12, 19),
      };

      wb.SheetNames.push("Test Sheet");
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Test Sheet"] = ws;

      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      this.downloadBlob(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Экспорт данных из ACT.xlsx",
      );
    },
    downloadBlob(content, filename, contentType) {
      //   // Create a blob
      //   var blob = new Blob([content], { type: contentType });
      //   var url = URL.createObjectURL(blob);

      //   // Create a link to download it
      //   var pom = document.createElement("a");
      //   pom.href = url;
      //   pom.setAttribute("download", filename);
      //   pom.click();

      var blob = new Blob([content], { type: contentType });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    showBank(el) {
      let auth = localStorage.getItem("user");
      let sber = "НЕТ";
      if (el.Order.bank) {
        sber = "ДА";
      }
      let html = `<div>${el.className} ${el.FirstName} ${el.LastName}</div>
      <div>Лицевой счёт: ${el.Order.ls}</div>
      <div>Реквизиты: ${el.Order.bank}</div>
      <div>Сбербанк: ${sber}</div>
      <div>Фамилия получателя: ${el.Order.recipientsLastName}</div>
      <div>Имя получателя: ${el.Order.recipientsFirstName}</div>
      <div>Отчество получателя: ${el.Order.recipientsSurname}</div>`;

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0",
      );
      tab.document.write(html);
      // tab.document.close();
      tab.focus();
      localStorage.setItem("user", auth);
    },
    check() {
      console.log(this.selectFilterClass);
    },
    inputNameChange() {
      if (this.inputName == "") {
        this.searchByName = "";
      }
    },
    changeClass() {
      this.paginatBtn.forEach((element) => {
        element.select = false;
      });
      this.paginatBtn[0].select = true;
    },
    changeSelectPaginatNumber(btn) {
      this.paginatBtn.forEach((element) => {
        element.select = false;
      });
      btn.select = true;
    },
    changeSelectPaginatListNumber(check) {
      let selectList;
      this.paginatBtnListAmount.forEach((element) => {
        if (element.select) {
          selectList = element.Number - 1;
        }
      });
      if (check) {
        if (selectList != this.paginatBtnListAmount.length) {
          this.paginatBtnListAmount[selectList + 1].select = true;
          this.paginatBtnListAmount[selectList].select = false;
          this.paginatBtn.forEach((element) => {
            element.select = false;
          });
          this.paginatBtn[(selectList + 1) * 10].select = true;
        }
      } else {
        if (selectList != 0) {
          this.paginatBtnListAmount[selectList - 1].select = true;
          this.paginatBtnListAmount[selectList].select = false;
          this.paginatBtn.forEach((element) => {
            element.select = false;
          });
          this.paginatBtn[(selectList - 1) * 10].select = true;
        }
      }
    },

    changePaginatAmount(students) {
      if (!students) students = this.students;
      this.paginatBtn = [];
      this.paginatBtnListAmount = [];
      let x = Math.ceil(students.length / this.paginatAmountTt);
      console.log(x);

      for (let i = 0; i < x; i++) {
        this.paginatBtn.push({ Number: i + 1, select: false });
      }
      let y = Math.ceil(this.paginatBtn.length / 10);
      for (let i = 0; i < y; i++) {
        this.paginatBtnListAmount.push({ Number: i + 1, select: false });
      }
      this.paginatBtn[0].select = true;
      this.paginatBtnListAmount[0].select = true;
    },
  },
  async mounted() {
    this.students = await this.getStudents();
    this.changePaginatAmount(this.students);
    this.classList = await this.getAllClass();
  },

  computed: {
    searchByNameF: function () {
      let x;

      if (this.searchByName != "") {
        x = this.students.filter(
          (item) =>
            item.FirstName.toUpperCase().indexOf(
              this.searchByName.toUpperCase(),
            ) !== -1,
        );
      } else {
        x = this.viewTable;
      }

      return x;
    },
    viewTable: function () {
      let indexNumber = 0;
      let students = this.students;
      let selectFilterClass = this.selectFilterClass;

      if (this.selectFilterClass != "Все") {
        students = students.filter(function (elem) {
          if (elem.classID === selectFilterClass.classID) {
            return elem;
          }
        });
      }
      // students.sort((a, b) => (a.ghostIndex > b.ghostIndex ? 1 : -1));

      let x = this.paginatBtn;
      let paginatAmountTt = Number(this.paginatAmountTt);
      x.forEach((element) => {
        if (element.select) {
          indexNumber = element.Number - 1;
        }
      });
      console.log(indexNumber);
      return students.slice(
        indexNumber * paginatAmountTt,
        indexNumber * paginatAmountTt + paginatAmountTt,
      );
    },
    viewPaginatBtn: function () {
      let data = this.paginatBtn;
      let indexNumber = 0;
      this.paginatBtnListAmount.forEach((element) => {
        if (element.select) {
          indexNumber = element.Number - 1;
        }
      });

      return data.slice(indexNumber * 10, indexNumber * 10 + 10);
    },
  },
};
</script>

<style lang="scss" scoped>
TABLE {
  overflow-x: auto;
}
.filters {
  display: flex;
  width: auto;
  margin: 10px;
}
.paginat {
  display: flex;
}
.paginat button {
  padding: 4px;
  min-width: 36px;
  line-height: 1.42857143;
  color: #18a2eb;
  // background-color: aquamarine;
  border: 1px solid #ddd;
  border-width: 0.1em;
}
.selectBtn {
  background-color: rgb(154, 215, 211);
}
.selectBtn:hover {
  background-color: rgb(223, 223, 223);
}
.unSelectBtn {
}
.unSelectBtn:hover {
  background-color: rgb(222, 222, 222);
}
</style>
