import TutorialDataService from "../services/TutorialDataService";

export default {
  methods: {
    async getAllClass() {
      let classList;
      //получить список всех классов (classList)
      await TutorialDataService.getAllCLass()
        .then((response) => {
          classList = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });
      return await classList;
    },

    async getStudents() {
      var allStudents = [];
      console.log("get");

      await TutorialDataService.findStudentByClassID().then((response) => {
        let students = Object.values(response.data);

        students.map((student) => {
          if (student != undefined) {
            console.log(1);
            allStudents.push(student);
          }
        });
      });
      let classList = await this.getAllClass();
      allStudents.sort(function (vote1, vote2) {
        if (vote1.classID > vote2.classID) return 1;
        if (vote1.classID < vote2.classID) return -1;

        if (vote1.FirstName > vote2.FirstName) return 1;
        if (vote1.FirstName < vote2.FirstName) return -1;
      });
      for (let p = 0; p < allStudents.length; p++) {
        for (let i = 0; i < classList.length; i++) {
          console.log(1);
          allStudents[p].ghostIndex = p;
          if (allStudents[p].classID === classList[i].classID) {
            allStudents[p].className = classList[i].className;
          }
        }
      }
      return await allStudents;
    },

    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
        shift: data.shift,
        change: false,
        count: 0,
        classLider: data.classLider,
        level: data.level,
      };
    },
  },
};

//Object.assign({}, ...mixins, ...component);

// export default get();
