import axios from "axios";

export default axios.create({
  baseURL: window.myVariable,
  // baseURL: "https://shaggy-moose-17.telebit.io/tutorials",

  headers: {
    "Content-type": "multipart/form-data",
    "ngrok-skip-browser-warning": 1,
    "Bypass-Tunnel-Reminder": 1,
    "X-TOKEN": "keylogsch24",
    "bypass-tunnel-reminder": 1,
    "Access-Control-Allow-Origin": "*",
  },
});
